var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form",staticClass:"pt-2",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('Filters',{attrs:{"hide_in_stock":"","category_type":"","favorite_switch":""},on:{"filter_update":_vm.load_and_upd_prods}}),_c('Table',{attrs:{"headers":_vm.cols,"items":_vm.courses_products,"loading":_vm.is_prods_loading,"custom-sort":_vm.prevent_sort,"not_resizeble":true,"no-data-text":"Товары, удовлетворяющие выбранным параметрам, отсутствуют","hide-default-footer":""},on:{"click:row":_vm.toggle_checkbox},scopedSlots:_vm._u([{key:"header.select",fn:function(){return [_c('v-checkbox',{attrs:{"input-value":_vm.all_selected,"indeterminate":_vm.intermediate},on:{"change":_vm.select_all_clicked}})]},proxy:true},{key:"item.select",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.$style.checkbox},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"hide-details":"","input-value":_vm.selected_courses.includes(item)},on:{"click":function($event){$event.stopPropagation();return _vm.add_product($event, item)}}})],1)]}},{key:"item.nomenclature",fn:function(ref){
var item = ref.item;
return [_c('div',{style:(_vm.is_mobile ? 'width:100px' : '')},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:_vm.$style.nomenclatureName},'div',attrs,false),on),[_c('span',{class:[_vm.is_mobile ? 'overflow-text' : '', _vm.$style.nomenclatureText]},[_vm._v(" "+_vm._s(item.nomenclature)+" ")])])]}}],null,true)},[_vm._v(" "+_vm._s(item.nomenclature)+" ")])],1)]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.$style.priceCell},[_c('div',{class:_vm.$style.prices},[_c('div',{class:_vm.$style['table-currency']},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.prices.actual.base.rub))+" ")])]),(item.prices.actual.base.rub > 0)?_c('PriceTooltips',{attrs:{"prices":item.prices.actual}}):_vm._e()],1)]}}],null,true)}),_c('Pagination',{class:_vm.$style.pagination,attrs:{"pagination_props":_vm.pagination_props,"hide_page_size":""},on:{"change_page":function($event){return _vm.upd_product_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_product_pagination('limit', $event)}}}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mb-6"}),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-auto",attrs:{"depressed":""},on:{"click":_vm.close}},[_vm._v(" Отменить ")]),(_vm.active_tab === 1)?_c('v-btn',{attrs:{"depressed":"","disabled":!_vm.form_valid},on:{"click":_vm.go_back}},[_vm._v(" Назад ")]):_vm._e(),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"accent darken-4","depressed":"","type":"submit","disabled":!_vm.selected_courses.length}},[(_vm.active_tab === 0)?[_vm._v(" Продолжить ")]:[_vm._v(" Сохранить ")]],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }